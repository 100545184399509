<template>
  <div id="members-subscrition-assign">
    <v-card>
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-card-title class="text-h5">
          <h2>{{ PageTitle }}</h2>
        </v-card-title>

        <v-card-subtitle class="text-h5">
          <h4>
            {{ PageDescription }}
          </h4></v-card-subtitle
        >
        <v-footer :fixed="true" :absolute="false" :padless="true">
          <v-card flat tile width="100%" color="#8950FC">
            <v-container>
              <v-row wrap>
                <v-col
                  align="center"
                  cols="6"
                  sm="6"
                  md="6"
                  @click.prevent="tab = 'tab-2'"
                >
                  <h3 class="text-white">
                    Old Members : {{ selected1.length }}
                  </h3>
                </v-col>
                <v-col
                  align="center"
                  cols="6"
                  sm="6"
                  md="6"
                  @click.prevent="tab = 'tab-3'"
                >
                  <h3 class="text-white">
                    New Members : {{ NewMembers.length }}
                  </h3>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row wrap>
                <v-col align="center" cols="6" md="6">
                  <h3 class="text-white">
                    Total Members : {{ TotalMembers }} /
                    {{ rows.RemainingCount }}
                  </h3>
                </v-col>
                <v-col align="center" cols="6" md="6">
                  <v-btn
                    :loading="SubmitFlag"
                    color="success"
                    class="mr-4"
                    @click="submitConfirmAlert"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-footer>
        <v-dialog v-model="addRecordPrompt" persistent max-width="75%">
          <add-new-member
            pageDescription="Create Staff"
            :addRecordPrompt="addRecordPrompt"
            @hideAddRecordPrompt="addNewMember($event)"
            v-if="addRecordPrompt"
          ></add-new-member>
        </v-dialog>

        <v-card-text>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn color="success" class="mr-4" @click="backPage">
                  Back
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <br />
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
            <br /><br />
          </div>
          <v-container class="py-0" v-if="StartupLoadingFlag">
            <v-skeleton-loader
              v-bind="attrs"
              type="table-tbody"
            ></v-skeleton-loader>
          </v-container>
          <v-container class="py-0" v-if="!StartupLoadingFlag">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h2 class="title1">
                  {{ rows.Discription }}
                </h2>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="4">
                <v-card color="#ff6b6b" dark hover>
                  <v-card-title class="card-title">
                    Subscription paid
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.PaidCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="success" dark hover>
                  <v-card-title class="card-title">
                    Totally selected
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.SelelctedCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4">
                <v-card color="warning" dark hover>
                  <v-card-title class="card-title">
                    Please select
                  </v-card-title>
                  <v-card-subtitle>
                    <br /><br />
                    <p class="card-subtitle">{{ rows.RemainingCount }}</p>
                  </v-card-subtitle>
                  <p class="card-title">Members</p>
                  <v-card-actions> </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <br />

          <v-container class="notes">
            <p>Important notes:</p>
            <p>Dear President, the below page has three tabs.</p>
            <ol>
              <li>
                The first tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-tie
                </v-icon>
                shows the previous half members.
              </li>
              <li>
                The second tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-group
                </v-icon>
                shows all the old members.
              </li>
              <li>
                The third tab
                <v-icon icon size="25px" color="primary">
                  mdi-account-plus
                </v-icon>
                is used to create new members to your LOM.
              </li>
              <li>
                For the new members created, the membership ID will be
                immediately created.
              </li>
              <li>
                The total count including all the three tabs cannot exceed the
                total subscription paid Subscription for the same member cannot
                be marked twice
              </li>
              <li>
                Selecting the members can be done partially also. Eg: If you
                have paid subscription for 10 members, you can choose few
                members today and choose the remaining members later also
              </li>
            </ol>
          </v-container>

          <br />

          <v-tabs
            v-model="tab"
            background-color="#8950FC"
            centered
            dark
            icons-and-text
            show-arrows
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" :disabled="TabDisable1">
              JAC Renewal
              <v-icon>mdi-account-group</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
              JAC New Members
              <v-icon>mdi-account-plus</v-icon>
            </v-tab>

            <v-tab href="#tab-3">
              Migrate Member
              <v-icon>mdi-account-plus</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">
                      Select from all other Members of the LOM
                    </h3>
                  </v-col>
                </v-row>
                <v-container
                  class="py-0"
                  v-if="
                    !LoadingFlag && ResultFlag && ExistingMembers.length == 0
                  "
                >
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h4>No members found.</h4>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="py-0" v-if="ExistingMembers.length > 0">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h3>{{ ExistingMembers.length }} members found</h3>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="ExistingMembers.length > 0">
                    <v-col align="center" cols="12" md="12">
                      <v-text-field
                        v-model="search2"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                      <br />
                      <v-data-table
                        class="elevation-1"
                        v-model="selected1"
                        :headers="tableColumns"
                        :items="ExistingMembers"
                        :search="search2"
                        :show-select="tableOptions.ShowSelectFlag"
                        :item-key="tableOptions.ItemKey"
                        :single-select="tableOptions.SingleSelectFlag"
                        :items-per-page="tableOptions.ItemsPerPage"
                        :footer-props="tableOptions.FooterProps"
                      >
                        <template v-slot:item.ActiveStatusTxt="{ item }">
                          <v-chip
                            :color="getActiveStatusColor(item.ActiveStatusTxt)"
                            draggable
                            dark
                            >{{ item.ActiveStatusTxt }}</v-chip
                          >
                        </template>
                        <template v-slot:item.ProfilePic="{ item }">
                          <img
                            width="100"
                            height="100"
                            :src="item.ProfilePic"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="25px"
                                color="warning"
                                @click="editAlert(item, e)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-file-document-edit
                              </v-icon>
                            </template>
                            <span> Edit </span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-2">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">Add New Members to the LOM</h3>
                  </v-col>
                </v-row>
                <v-container class="py-0" v-if="rows.NewMembersFlag">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <br />
                      <v-simple-table class="new-member-table">
                        <template>
                          <thead>
                            <tr>
                              <th class="text-center">
                                <h2>Member Name</h2>
                              </th>
                              <th class="text-center">
                                <h2>Email</h2>
                              </th>
                              <th class="text-center">
                                <h2>Mobile No</h2>
                              </th>
                              <th class="text-center">
                                <h2>Address</h2>
                              </th>
                              <th>
                                <v-btn
                                  icon
                                  color="blue"
                                  @click.prevent="addRecordPrompt = true"
                                >
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, k) in NewMembers" :key="k">
                              <td>
                                {{ item.MemberName }}
                              </td>
                              <td>
                                {{ item.MemberEmail }}
                              </td>
                              <td>
                                {{ item.MemberMobileNo }}
                              </td>
                              <td class="td-city">
                                <p
                                  v-html="item.MemberAddressTxt"
                                  style="font-size: 14px"
                                ></p>
                              </td>
                              <td>
                                <v-btn
                                  icon
                                  color="blue"
                                  @click.prevent="addRecordPrompt = true"
                                >
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  color="red"
                                  @click="deleteRow(2, k, item)"
                                >
                                  <v-icon>mdi-delete-circle-outline</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <br /><br />
                </v-container>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-3">
              <v-card flat>
                <p></p>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3 class="text-primary">
                      Select from all other Members of the LOM
                    </h3>
                  </v-col>
                </v-row>
                <v-container
                  class="py-0"
                  v-if="
                    !LoadingFlag && ResultFlag && CurrentJciMembers.length == 0
                  "
                >
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h4>No members found.</h4>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container class="py-0" v-if="CurrentJciMembers.length > 0">
                  <br />
                  <v-row wrap>
                    <v-col align="center" cols="12" md="12">
                      <h3>{{ CurrentJciMembers.length }} members found</h3>
                    </v-col>
                  </v-row>
                  <v-row wrap v-if="CurrentJciMembers.length > 0">
                    <v-col align="center" cols="12" md="12">
                      <v-text-field
                        v-model="search2"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                      <br />
                      <v-data-table
                        class="elevation-1"
                        v-model="selected2"
                        :headers="tableColumns"
                        :items="CurrentJciMembers"
                        :search="search2"
                        :show-select="tableOptions.ShowSelectFlag"
                        :item-key="tableOptions.ItemKey"
                        :single-select="tableOptions.SingleSelectFlag"
                        :items-per-page="tableOptions.ItemsPerPage"
                        :footer-props="tableOptions.FooterProps"
                      >
                        <template v-slot:item.ActiveStatusTxt="{ item }">
                          <v-chip
                            :color="getActiveStatusColor(item.ActiveStatusTxt)"
                            draggable
                            dark
                            >{{ item.ActiveStatusTxt }}</v-chip
                          >
                        </template>
                        <template v-slot:item.MemberImagePath="{ item }">
                          <img
                            width="100"
                            height="100"
                            :src="item.MemberImagePath"
                          />
                        </template>
                        <template v-slot:item.actions="{ item }"> </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

          <br /><br />
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import AddNewMember from "@/view/pages/erp/payment/AddNewMember.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    AddNewMember,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      TabDisable1: false,
      TabDisable2: false,

      rows: [],
      tableColumns: [],
      tableOptions: {},
      selected: [],
      search: "",
      search2: "",
      CurrentYearId: 0,

      addRecordPrompt: false,

      zone: {},
      lom: {},
      SmOrderId: "",
      PreviousHalfMembers: [],
      ExistingMembers: [],
      Member: {},

      CurrentJciMembers: [],
      selected2: [],

      selected1: [],
      NewMembers: [],
      TotalMembers: 0,

      tab: null,

      ZoneCode: "",
      LomCode: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log("CurrentJciYearId=" + CurrentJciYearId);
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomCode = this.$session.get("LomId");
        this.LomCode = LomCode == (null || undefined) ? 0 : LomCode;
        console.log("LomCode=" + LomCode);

        var ZoneCode = this.$session.get("ZoneId");
        this.ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        this.ZoneCode = ZoneCode;

        this.getTableRecords();
        // this.getAllMembers();
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
    selected1: function () {
      console.log("watch selected1");
      this.calculateTotalMembers();
    },
    selected2: function () {
      console.log("watch selected2");
      this.calculateTotalMembers();
    },
    NewMembers: function () {
      console.log("watch NewMembers");
      this.calculateTotalMembers();
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.StartupLoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "senior_members",
        Action: "create_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    backPage() {
      this.$router.go(-1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form2.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    getAllMembers() {
      console.log("getAllMembers called");

      // this.resetMessageTxt();
      this.selected1 = [];
      this.ExistingMembers = [];

      var LomCode = this.rows.LomId;
      var YearCode = this.JciYearCode;
      console.log("LomCode=" + LomCode + ", YearCode=" + YearCode);

      if (LomCode != "" && YearCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/members/list";
        var upload = {
          UserInterface: 2,
          additional: {
            LomCode: LomCode,
            YearCode: YearCode,
          },
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.tableColumns = records.TableHeader;
              thisIns.ExistingMembers = records.TableData;
              thisIns.tableOptions = records.TableOptions;
              console.log("success=" + output);
            } else {
              console.log("error=" + output);
            }
          })
          .catch(function (error) {
            thisIns.LoadingFlag = false;
            console.log("error=" + error);
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    getCurrentJciMembers() {
      console.log("getCurrentJciMembers called");

      // this.resetMessageTxt();
      this.selected2 = [];
      this.CurrentJciMembers = [];

      var LomCode = this.rows.LomId;
      var YearCode = this.JciYearCode;
      console.log("LomCode=" + LomCode + ", YearCode=" + YearCode);

      if (LomCode != "" && YearCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-dues/lists";
        var upload = {
          UserInterface: 2,
          MemberType: 1,
          YearCode: YearCode,
          LomCode: LomCode,
          YearHalf: "First",
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            var TableContent = response.data.table_content;
            console.log({ TableContent });

            if (flag == 1) {
              thisIns.CurrentJciMembers = records;
              thisIns.tableColumns = TableContent.TableHeader;
              thisIns.tableOptions = TableContent.TableOptions;
              console.log("success=" + output);
            } else {
              console.log("error=" + output);
            }
          })
          .catch(function (error) {
            thisIns.LoadingFlag = false;
            console.log("error=" + error);
          });
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "LOM should not be empty.";
      }
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var SmOrderId = this.SmOrderId;
      console.log("SmOrderId=" + SmOrderId);

      if (SmOrderId != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/members/receipt/show";
        var upload = {
          UserInterface: 1,
          SmOrderId: SmOrderId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.rows = records;
              thisIns.getAllMembers();
              thisIns.getCurrentJciMembers();
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Order Id should not be empty.";
        console.log("error=" + message);
      }
    },
    calculateTotalMembers() {
      console.log("calculateTotalMembers called");
      var c1 = this.selected1.length;
      var c2 = this.NewMembers.length;
      var c3 = this.selected2.length;
      console.log("c1=" + c1 + ", c2=" + c2 + ", c3=" + c3);
      var TotalMembers = parseInt(c1) + parseInt(c2) + parseInt(c3);
      console.log("TotalMembers=" + TotalMembers);
      this.TotalMembers = TotalMembers;
    },
    addNewMember(member) {
      console.log("addNewMember called");
      this.addRecordPrompt = false;
      console.log("Member=" + JSON.stringify(member));
      this.Member = member;
      this.addNewRow(2);
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        var MemberFlag = this.Member.MemberFlag;
        console.log("MemberFlag=" + MemberFlag);
        if (MemberFlag) {
          this.NewMembers.push(this.Member);
        }
        // if (n1 < 30) {
        this.Member = {};
        // }
        /*
        {
          MemberName: "",
          MemberMiddleName: "",
          MemberLastName: "",
          MemberEmail: "",
          MemberMobileNo: "",
          MemberAddress: "",
          MemberCity: "",
          MemberPincode: "",
        }
        */
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    submitConfirmAlert() {
      Swal.fire({
        title: "Do you want to continue?",
        text: "Please verify all the information before proceeding.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var RemainingCount = this.rows.RemainingCount;
      console.log("RemainingCount=" + RemainingCount);

      var NewMembers = this.NewMembers;
      var n1 = NewMembers.length;
      console.log("n1=" + n1 + ", NewMembers=" + JSON.stringify(NewMembers));

      var ExistingMembers = this.selected1;
      var n2 = ExistingMembers.length;
      console.log(
        "n2=" + n2 + ", ExistingMembers=" + JSON.stringify(ExistingMembers)
      );

      var CurrentJciMembers = this.selected2;
      var n3 = CurrentJciMembers.length;
      console.log(
        "n3=" + n3 + ", CurrentJciMembers=" + JSON.stringify(CurrentJciMembers)
      );

      var TotalCount = parseInt(n1) + parseInt(n2) + parseInt(n3);
      console.log("TotalCount=" + TotalCount);

      if (validate1 && TotalCount <= RemainingCount && RemainingCount > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior/members/assign";
        var upload = {
          SmOrderId: this.SmOrderId,
          TotalMembers: TotalCount,
          NewMembers: NewMembers,
          ExistingMembers: ExistingMembers,
          CurrentJciMembers: CurrentJciMembers,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.$router.push("/senior-members/receipts/list");
              // thisIns.$router.push("/lom/membership-list");
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        if (TotalCount > RemainingCount && RemainingCount > 0) {
          message +=
            "You can select or create within " +
            RemainingCount +
            " members only. ";
        }
        if (RemainingCount == 0) {
          message += "Already you selected all members for this subscription. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.SmOrderId = this.$route.query.id;
    console.log("SmOrderId=" + this.SmOrderId);
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    this.CurrentYearId = CurrentYearId;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#members-subscrition-assign {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }

  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtitle1 {
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    color: white;
    text-align: center;
    line-height: 40px;
  }

  .title1 {
    font-weight: bold;
    font-family: "Libre Baskerville", serif;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
}
</style>